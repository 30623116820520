#root {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

[tabindex] {
  outline: none !important;
}

.container-fluid{
  padding: 0;
  margin: 0;
}
.app-bottomNav-display{
  display: none;
}

@media only screen and (min-width: 992px){
  .container-fluid{
    padding-left: 2em !important;
    margin: 0;
  }
}

.row{
  margin:auto !important;
}
@media only screen and (max-width: 992px){
  .app-bottomNav-display{
    display: block;
  }
  .main-content{
    padding-top: 50px;
    padding-bottom: 80px;
  }
}
@font-face {
  font-family: 'Gilroy-Black';
  src: local('Gilroy-Black'), url(./fonts/Gilroy-Black.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-BlackItalic';
  src: local('Gilroy-BlackItalic'), url(./fonts/Gilroy-BlackItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-BoldItalic';
  src: local('Gilroy-BoldItalic'), url(./fonts/Gilroy-BoldItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(./fonts/Gilroy-ExtraBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-ExtraBoldItalic';
  src: local('Gilroy-ExtraBoldItalic'), url(./fonts/Gilroy-ExtraBoldItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-Heavy';
  src: local('Gilroy-Heavy'), url(./fonts/Gilroy-Heavy.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-HeavyItalic';
  src: local('Gilroy-HeavyItalic'), url(./fonts/Gilroy-HeavyItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-LightItalic';
  src: local('Gilroy-LightItalic'), url(./fonts/Gilroy-Heavy.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-MediumItalic';
  src: local('Gilroy-MediumItalic'), url(./fonts/Gilroy-MediumItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(./fonts/Gilroy-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-RegularItalic';
  src: local('Gilroy-RegularItalic'), url(./fonts/Gilroy-RegularItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url(./fonts/Gilroy-SemiBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-SemiBoldItalic';
  src: local('Gilroy-SemiBoldItalic'), url(./fonts/Gilroy-SemiBoldItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-Thin';
  src: local('Gilroy-Thin'), url(./fonts/Gilroy-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-ThinItalic';
  src: local('Gilroy-ThinItalic'), url(./fonts/Gilroy-ThinItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-UltraLight';
  src: local('Gilroy-UltraLight'), url(./fonts/Gilroy-UltraLight.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Gilroy-UltraLightItalic';
  src: local('Gilroy-UltraLightItalic'), url(./fonts/Gilroy-UltraLightItalic.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
