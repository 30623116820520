.upper-navbar{
    top: 0;
    /* position: -webkit-sticky; */
    position: fixed;
    width: 100%;
    height: 60px;
    background-color: white;
    float: right;
    z-index: 500;
    box-shadow: 0 0 15px -5px rgb(164, 165, 228);
    
}
.color-blue{
    color: #0B07DC;
}
.upper-left-element{
    margin-left: 12px;
}
.upper-left-element img{
    height: 40px;
    width: 41px;
}
.upper-navbar a:focus{
    text-decoration: none;
    outline: none;
    border: none;
}
.upper-left-element p{
    margin-top: 12px;
    color: black;
    text-decoration: none;
    margin-left: 8px;
}
.upper-right-element img{
    width: 30px;
    height: 31px;
}
@media only screen and (max-width: 318px){
    .upper-left-element p{
        display: none;
    }
  }
